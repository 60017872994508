import Image from "next/image";
import { memo } from "react";
import BlogSlider from "../common/BlogSlider";
import Behance from "../svg/Behance";
import Dribbble from "../svg/Dribbble";
import Instagram from "../svg/Instagram";
import Pinterest from "../svg/Pinterest";

const getImageSource = (data) => (index) => {
  if (
    data?.designShots?.status === "success" &&
    data.designShots.data[index]?.image
  ) {
    return data.designShots.data[index].image;
  }
  return "";
};

const FirstRow = ({ data }) => {
  const getImage = getImageSource(data);
  return (
    <div className="flex flex-wrap">
      <div className="w-1/2 md:w-4/12 p-4">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(0)}
            width={500}
          />
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(1)}
            width={500}
          />
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4 hidden md:block">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(2)}
            width={500}
          />
        </div>
      </div>
    </div>
  );
};
const SecondRow = ({ data }) => {
  const getImage = getImageSource(data);
  return (
    <div className="flex flex-wrap">
      <div className="w-1/2 md:w-4/12 p-4">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(3)}
            width={500}
          />
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4">
        <div>
          <h3 className="text-2xl md:text-3xl xl:text-6xl font-medium md:mb-3 xl:leading-snug">
            <span>Look at our</span>
          </h3>
        </div>
        <div className="md:flex justify-between">
          <div>
            <h3 className="text-2xl md:text-3xl xl:text-6xl font-medium md:mb-3 xl:leading-snug">
              <span>Design</span>
            </h3>
            <h3 className="text-2xl md:text-3xl xl:text-6xl font-medium xl:leading-snug">
              <span>Creatives</span>
            </h3>
          </div>
          <div className="flex flex-row md:flex-col justify-between md:justify-center items-end mt-4 md:mt-12 xl:-mt-16 pr-8">
            <a
              className="text-2xl mx-1 md:mx-0 md:text-4xl text-black"
              href="https://www.behance.net/ultroneous"
              rel="noreferrer"
              target="_blank"
              title="Designing shots"
            >
              <Behance
                className="w-4 h-4 md:w-8 md:h-8 md:mt-4 xl:mt-8 hover:fill-primary-yellow"
                fill="black"
                width={16}
                height={16}
              />
            </a>
            <a
              className="text-2xl mx-1 md:mx-0 md:text-4xl text-black"
              href="https://dribbble.com/ultroneous"
              rel="noreferrer"
              target="_blank"
              title="Website designs theme"
            >
              <Dribbble
                fill="black"
                width={16}
                height={16}
                className="w-4 h-4 md:w-8 md:h-8 md:mt-4 xl:mt-8 hover:fill-primary-yellow"
              />
            </a>
            <a
              className="text-2xl mx-1 md:mx-0 md:text-4xl text-black"
              href="https://in.pinterest.com/ultroneoustechnologies/"
              rel="noreferrer"
              target="_blank"
              title="version control"
            >
              <Pinterest
                fill="black"
                width={16}
                height={16}
                className="w-4 h-4 md:w-8 md:h-8 md:mt-4 xl:mt-8 hover:fill-primary-yellow"
              />
            </a>
            <a
              className="text-2xl mx-1 md:mx-0 md:text-4xl text-black"
              href="https://www.instagram.com/ultroneous.technologies/"
              rel="noreferrer"
              target="_blank"
              title="Follow us on Instagram"
            >
              <Instagram
                fill="black"
                width={16}
                height={16}
                className="w-4 h-4 md:w-8 md:h-8 md:mt-4 xl:mt-8 hover:fill-primary-yellow"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4 hidden md:block">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(4)}
            width={500}
          />
        </div>
      </div>
    </div>
  );
};
const ThirdRow = ({ data }) => {
  const getImage = getImageSource(data);
  return (
    <div className="flex flex-wrap">
      <div className="w-1/2 md:w-4/12 p-4">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(5)}
            width={500}
          />
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4 hidden md:block">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            src={getImage(6)}
            width={500}
          />
        </div>
      </div>
      <div className="w-1/2 md:w-4/12 p-4">
        <div className="overflow-hidden fs-0">
          <Image
            alt="look at our design"
            className="w-full transition-all duration-500 ease-out hover:ease-in hover:scale-110"
            height={500}
            objectFit="contain"
            priority={false}
            src={getImage(7)}
            width={500}
          />
        </div>
      </div>
    </div>
  );
};
const DesignShots = ({ data }) => (
  <div>
    {data.designShots.data.length && (
      <div className="py-4 px-2 md:pt-8 md:px-8 md:pb-24">
        <FirstRow data={data} />
        <SecondRow data={data} />
        <ThirdRow data={data} />
      </div>
    )}
    {data.blog.length > 0 ? <BlogSlider list={data.blog} /> : <></>}
  </div>
);
export default memo(DesignShots);
