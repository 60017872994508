import { cmsUrl } from "@/utils/helper";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import Container from "./Container";

const BlogSlider = (properties) => {
  const [viewImage, setViewImage] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: properties.list.length >= 3 ? 3 : 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 885,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
    ],
  };
  const sliderReference = useRef();
  const getWPImage = useCallback(async () => {
    const newImageBucket = [];
    for (let index = 0; index < properties.list.length; index++) {
      const element = properties.list[Number.parseInt(index)];
      await axios
        .get(`${cmsUrl}/wp-json/wp/v2/media/${element.featured_media}`)
        .then((r) => {
          newImageBucket.push(r.data?.source_url);
        });
    }
    return newImageBucket;
  }, [properties.list]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const image = await getWPImage();
    setViewImage(image);
  }, [getWPImage]);
  return (
    <Container large>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-8/12 lg:w-6/12">
          <h2 className="text-5xl md:text-6xl font-medium mb-2">
            <span>Featured Insights</span>
          </h2>
        </div>
        <div
          className={`md:w-4/12 lg:w-6/12 ${
            properties.list.length >= 3
              ? "flex justify-start md:justify-end items-center pb-16 md:pb-0"
              : "hidden"
          }`}
        >
          <div className="pr-4">
            <Image
              alt="arrow"
              className="cursor-pointer transition-all duration-500 ease-in hover:transition-all hover:duration-500 hover:ease-in hover:scale-90 h-16"
              src={"/assets/common/left-arrow.webp"}
              onClick={() => sliderReference.current.slickPrev()}
              width={72}
              height={72}
            />
          </div>
          <div className="pr-4">
            <Image
              alt="arrow"
              className="cursor-pointer transition-all duration-500 ease-in hover:transition-all hover:duration-500 hover:ease-in hover:scale-90 h-16"
              src={"/assets/common/right-arrow.webp"}
              onClick={() => sliderReference.current.slickNext()}
              width={72}
              height={72}
            />
          </div>
        </div>
      </div>
      <div>
        <Slider {...settings} ref={sliderReference}>
          {properties.list
            ? properties.list.length > 0
              ? properties.list.map((v, index) => (
                  <div key={v.title} className=" md:pr-2 lg:pr-0 w-full">
                    <Link href={`/blog/${v.slug}`} rel="noreferrer">
                      <a target="_blank">
                        <div className="mr-12 pt-8">
                          <div>
                            <Image
                              alt="blog"
                              className="w-full h-full"
                              src={viewImage[Number.parseInt(index)] ?? "/"}
                              width={387}
                              height={317}
                            />
                          </div>
                          <div className="relative text-white bottom-8 left-6 md:left-10 lg:left-6 py-8 px-4 bg-black-chocolate">
                            <div className="relative flex justify-between">
                              <h3 className="text-base md:text-xl text-white w-10/12 pr-4">
                                <span>{v.title.rendered.slice(0, 45)}...</span>
                              </h3>
                              <div className="float-right">
                                <Image
                                  alt="blog-arrow"
                                  src="/assets/blog-arrow.webp"
                                  width={44}
                                  height={18}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </Link>
                  </div>
                ))
              : undefined
            : undefined}
        </Slider>
      </div>
    </Container>
  );
};
export default BlogSlider;
